<template>
    <sections-base class="faq" inner-class="bg-ocean-700">
        <header class="">
            <headline>Häufige Fragen</headline>
        </header>

        <div
            v-for="category in filteredCategories"
            :key="category.id"
            class="category"
        >
            <h3>{{ category.name }}</h3>

            <collapse
                v-for="entry in category.questions"
                :key="entry.id"
                ref="entries"
                v-model:state="states[entry.id]"
                :data-slug="entry.slug"
                @update:state="
                    (state) =>
                        updateRouteQuery({ q: state ? entry.slug : undefined })
                "
            >
                <template #header>{{ entry.question }}</template>
                <template #content><div v-html="entry.answer"></div></template>
            </collapse>
        </div>

        <div class="button-container" v-if="showButton">
            <butty class="accent shadow" :to="{ name: 'faq' }"
                >FAQ Kennenlernen</butty
            >
        </div>
    </sections-base>
</template>

<script>
import _each from 'lodash/each.js'
import _find from 'lodash/find.js'
import _findIndex from 'lodash/findIndex.js'
import useStrapiStore from '~/stores/strapi.js'

export default {
    props: {
        categoryFilter: {
            type: String,
            default: '',
        },
        showButton: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const strapiStore = useStrapiStore()
        const scroll = useScroll()

        return {
            strapiStore,
            scroll,
        }
    },

    data() {
        return {
            states: [],
            skipScroll: false,
        }
    },

    computed: {
        entries() {
            const list = []

            _each(this.strapiStore.faq, (category) => {
                list.push(...category.questions)
            })

            return list
        },
        filteredCategories() {
            if (this.categoryFilter) {
                return this.strapiStore.faq.filter(
                    (category) =>
                        category.name.toLowerCase() ===
                        this.categoryFilter.toLowerCase(),
                )
            }
            return this.strapiStore.faq
        },
    },

    watch: {
        entries: {
            handler() {
                _each(this.entries, (entry) => {
                    this.states[entry.id] = this.states[entry.id] ?? false
                })
            },
            immediate: true,
        },
    },

    mounted() {
        setTimeout(() => {
            this.strapiStore.fetchFAQ()
        }, 500)

        this.$watch(() => this.$route.query?.q ?? '', {
            handler(slug) {
                const index = _findIndex(this.entries, (q) => {
                    return q.slug.toLowerCase() === slug.toLowerCase()
                })

                let entry
                if (index > -1) {
                    entry = this.entries[index]

                    if (!this.skipScroll) {
                        const el = this.getQuestionElementBySlug(entry.slug)
                        if (el) {
                            setTimeout(() => {
                                this.scroll.toElement(el, {
                                    align: 'center',
                                })
                            }, 400)
                        }
                    }
                }

                this.handleUpdateState(entry)
                this.skipScroll = false
            },
            immediate: true,
        })
        // })
    },

    methods: {
        handleUpdateState(entry) {
            _each(this.entries, (item) => {
                if (entry && item.slug === entry.slug) {
                    this.states[item.id] = true
                } else {
                    this.states[item.id] = false
                }
            })
        },

        updateRouteQuery(query) {
            const mergedQuery = Object.assign({}, this.$route.query, query)
            this.skipScroll = true
            this.$router.replace({
                name: this.$route.name,
                query: mergedQuery,
            })
        },

        getQuestionElementBySlug(slug) {
            const collapse = _find(
                this.$refs.entries,
                ({ $el }) => $el.getAttribute('data-slug') === slug,
            )

            return collapse.$el
        },
    },
}
</script>

<style lang="scss" scoped>
.category {
    margin: 0 0 2rem;

    h3 {
        margin: 0 0 1.5rem;
    }
}

.button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
</style>
