<template>
    <sections-base
        class="how-it-works"
        background-class="bg-ocean-300"
        inner-class="py-big"
    >
        <header class="">
            <headline class="">So einfach geht's</headline>
        </header>

        <ul class="service-steps">
            <li>
                <figure>
                    <img
                        src="/images/clothes/how-it-works/altkleider-01.svg"
                        alt=""
                        width="130"
                        height="101"
                    />
                </figure>
                <h3>1. Tragbare Kleidung aussortieren</h3>
                <p>Befreie dich von überflüssigen Stücken.</p>
            </li>
            <li>
                <figure>
                    <img
                        src="/images/clothes/how-it-works/plz-eingeben-01.png"
                        alt=""
                        width="154"
                        height="115"
                    />
                </figure>
                <h3>2. PLZ online angeben</h3>
                <p>
                    Dein Standort entscheidet über unseren Service: Abholung
                    durch uns, unseren Versandpartner DHL oder Abgabe in der
                    nächsten Packstation.
                </p>
            </li>
            <li>
                <figure>
                    <img
                        src="/images/clothes/how-it-works/altkleider-sortiert-01.svg"
                        alt=""
                        width="132"
                        height="115"
                    />
                </figure>
                <h3>3. Menge angeben</h3>
                <p>Geschätzt oder gewogen.</p>
            </li>
        </ul>

        <butty class="accent shadow" :to="{ name: 'old-clothes' }"
            >Jetzt kostenlos buchen</butty
        >
        <div class="after-that">
            <ul class="service-steps relative z-10">
                <li>
                    <figure>
                        <img
                            src="/images/clothes/how-it-works/termin-01.svg"
                            alt=""
                            width="156"
                            height="109"
                        />
                    </figure>
                    <h3>4. Termin buchen</h3>
                    <p>
                        Wähle den Zeitraum, in dem wir oder unser Versandpartner
                        DHL bei dir vorbeikommen dürfen.
                    </p>
                </li>
                <li>
                    <figure>
                        <img
                            src="/images/clothes/how-it-works/textilien-abgeben-01.png"
                            alt=""
                            width="154"
                            height="108"
                        />
                    </figure>
                    <h3>5. Gib die Textilien ab</h3>
                    <p>
                        Gib die Altkleider an uns bzw. DHL ab oder bring sie
                        zeitnah zur nächsten Packstation.
                    </p>
                </li>
                <li>
                    <figure>
                        <img
                            src="/images/clothes/how-it-works/kreislauf-01.svg"
                            alt=""
                            width="144"
                            height="123"
                        />
                    </figure>
                    <h3>6. Möglichst lokale Wiederverwendung</h3>
                    <p>
                        Über unsere Secondhandpartner finden die Klamotten
                        möglichst lokal eine zweite Chance.
                    </p>
                </li>
            </ul>
        </div>
    </sections-base>
</template>

<script>
export default {
    async setup() {
        const [order] = await Promise.all([useOrderTools()])
        return {
            order,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/config';

.service-steps {
    display: flex;
    width: 90%;
    margin: 0 auto 3rem;
    flex-direction: column;
    gap: 3rem;

    li {
        text-align: center;
        width: 100%;
    }

    figure {
        position: relative;
        display: block;
        width: 35%;
        height: auto;
        margin: 0 auto 1rem;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        max-width: none;
    }

    h3 {
        @apply text-lg;
        margin: 0 0 0.25rem;
    }

    p {
        @apply text-sm;
        width: 80%;
        margin: 0 auto;
    }
}

.butty {
    @apply text-sm;
    margin: 0 auto 4rem;
}

header,
.service-steps,
.butty {
    z-index: 10;
}

.after-that {
    > h3 {
        @apply text-xl;
        text-transform: uppercase;
        margin: 0 0 2.5rem;
    }

    .service-steps {
        margin-bottom: 0;
    }
}

:deep(.section-base-background) {
    overflow: hidden;

    .icon {
        position: absolute;
        display: block;
    }
}

@screen sm {
    .service-steps {
        li {
            width: 70%;
            margin: 0 auto;
        }

        figure {
            width: 25%;
        }

        h3 {
            @apply text-xl;
            margin: 0 0 0.5rem;
        }

        p {
            width: auto;
        }
    }
}

@screen md {
    .service-steps {
        width: 80%;
        margin: 0 auto 3rem;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 0;

        li {
            width: 30%;
            margin: 0;
        }

        figure {
            width: 40%;
            height: 4rem;
        }

        img {
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

        h3 {
            display: flex;
            flex-flow: column;
            justify-content: center;
            min-height: 3rem;
        }
    }

    .butty {
        @apply text-md;
    }
}

@screen lg {
    .service-steps {
        figure {
            width: 50%;
            height: 5rem;
        }
    }
}

@screen xl {
    .service-steps {
        width: 70%;

        figure {
            width: 60%;
            height: 6rem;
        }
    }

    .butty {
        margin-bottom: 5rem;
    }
}
</style>
